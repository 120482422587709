import React from 'react'

import Layout from '../components/layout'
import Margin from '../components/Margin'
import SEO from '../components/seo'

const trygghetsfunktioner: React.FC = () => (
  <Layout>
    <SEO title="Trygghetsfunktioner och välmående" />
    <Margin>
      <h1>Hej n0llan!</h1>
      <p>Det här är sidan för dig som antingen vill veta mer om vilka trygghetsfunktioner som finns på Sektionen eller som vill anmäla något till antingen Trygghetsrådet eller Skyddsombud.</p>
      <p>Trygghetsrådet kontaktar du om du varit med om eller bevittnat en situation där sektionens Uppförandepolicy brutits mot (denna finns länkad längre ner). Skyddsombud kontaktar du om du exempelvis har någonting du vill dela gällande din eller någon annans trivsel i skolan, för feedback gällande säkerhet i lokaler eller vill komma i kontakt med någon annan instans på KTH som du behöver stöttning av.</p>
      <p>Om du har frågor eller funderingar kring något kopplat till JML, Sektionens trygghetsfunktioner eller Uppförandepolicyn går det givetvis också jättebra att höra av sig.</p>
      <p>Samtliga medlemmar i Trygghetsrådet har tystnadsplikt.</p>
      <p>Under rubrikerna nedan står det lite mer om just Trygghetsrådet och Skyddsombud. Vill du veta mer kring vilka resurser som finns på Sektionen kan du läsa på här: <a href="https://drive.google.com/file/d/1NdvhYHtz8UqyB4-6h8MjT4NxUVnj0tZZ/view?usp=sharing">Sektionens resurser för JML</a></p>
      <p>Anmälan till Trygghetsrådet (kan inte göras anonymt): <a href="https://docs.google.com/forms/d/e/1FAIpQLSfoblX_LtHPZYEc3jOn5Y-HyGiQJk_Gir4pdK9aDVL0JfkZjw/viewform">Formulär för anmälan till Trygghetsrådet</a></p>
      <p>Kontakt via formulär till Skyddsombud (kan göras anonymt): <a href="https://docs.google.com/forms/d/e/1FAIpQLScmNc5y1MaOz-vk1gkn79-JDfuOCUKhcE7rqh0eyD3ZUSujzQ/viewform">Kontaktformulär till Skyddsombud</a></p>
      <h2>TRYGGHETSRÅDET</h2>
      <p>Trygghetsrådet består av Skyddsombud, ordförande för GenI och Socialt ansvarig i Styrelsen. Trygghetsrådet kontaktar du om du varit med om eller bevittnat en situation där sektionens Uppförandepolicy brutits mot.</p>
      <p>Kortfattat så beskriver Uppförandepolicyn att man som sektionsmedlem inte får kränka, diskriminera eller aktivt exkludera någon annan. Uppförandepolicyn måste följas av alla sektionsmedlemmar och deltagare på sektionsaktiviteter och gäller alltid på eller strax efter ett event arrangerat av Sektionen, samt i eller vid sektionslokalen och sektionsbilen. Uppförandepolicyn i sin helhet går att hitta här: <a href="https://iare-strapi-backend.s3.eu-north-1.amazonaws.com/Uppfoerandepolicy_1_d4eaa7d0bf.pdf">Uppförandepolicy</a></p>
      <p>Uppförandepolicyn. Något som är viktigt att veta är att alla i Trygghetsrådet har tystnadsplikt.</p>
      <p>Anmälningar till Trygghetsrådet görs genom ett formulär på hemsidan. Dessa anmälningar kan INTE GÖRAS ANONYMT. Detta för att eventuella utredningar, vilka kan komma att leda till konsekvenser, måste kunna kopplas till berörda individer. Vilka konsekvenser det kan handla om står att läsa om i Uppförandepolicyn.</p>
      <p>Mail: <a href="mailto:trygghetsradet@iare.nu"></a>trygghetsradet@iare.nu</p>
      <h3>Läsåret 24/25 består Trygghetsrådet av följande medlemmar:</h3>
      <p><i>Skyddsombud:</i> Sofia Öhrström, <a href="mailto:skyddsombud@iare.nu">skyddsombud@iare.nu</a></p>
      <p><i>Ordförande för GenI:</i> Alva Åslund, <a href="mailto:geni@iare.nu">geni@iare.nu</a></p>
      <p><i>Socialt ansvarig i Styrelsen:</i> Sakarias Åman Rosengren, <a href="mailto:socialt@iare.nu">socialt@iare.nu</a></p>
      <h2>SKYDDSOMBUD</h2>
      <p>Enligt arbetsmiljölagen måste varje sektion ha ett studerandeskyddsombud. Det är en person som ansvarar för den studiesociala miljön på sektionen. Detta inkluderar både rättvisa mellan studenter och lärare och beteende studenter emellan, men också fysiska aspekter som säkerhet på campus. Skyddsombudet finns alltså till för att du ska känna dig trygg och välkommen i skolan.</p>
      <p>Sektionens Skyddsombud har även kontakt med andra Skyddsombud på KTH, Studiesocialt och Studiemiljöansvarig på THS samt KTH säkerhet. Du kan kontakta Skyddsombud om du exempelvis har någonting du vill dela gällande din eller någon annans trivsel i skolan, för feedback gällande säkerhet i lokaler eller vill komma i kontakt med någon annan instans på KTH som du behöver stöttning av.</p>
      <p>Något som är viktigt att veta är att Skyddsombud har tystnadsplikt, en tystnadsplikt som också regleras av arbetsmiljölagen. Du kontaktar Skyddsombud genom att antingen mejla eller genom ett formulär på hemsidan. Detta KAN GÖRAS ANONYMT.</p>
      <p>Mail: Sofia Öhrström, <a href="skyddsombud@iare.nu">skyddsombud@iare.nu</a></p>
    </Margin>
  </Layout>
)

export default trygghetsfunktioner
